<template>
  <div>
    <account-statement-table />
    <payments-table v-if="adNetwork.settings.payments" class="mt-2" />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import AccountStatementTable from './AccountStatementTable.vue'
  import PaymentsTable from './PaymentsTable.vue'

  export default {
    name: 'Finances',
    components: {
      AccountStatementTable,
      PaymentsTable
    },
    computed: {
      ...mapState('settings', ['adNetwork'])
    }
  }
</script>
