<template>
  <div>
    <div class="finances__header-container">
      <h3 class="text-h6">
        {{ $t('finances.finances') }}
      </h3>

      <div class="datepicker">
        <date-picker-select
          v-model="period"
          :text-field-props="{ backgroundColor: 'secondary' }"
          with-presets
          buttons
        />
      </div>
    </div>
    <v-card elevation="0">
      <v-card-title class="black">
        <h3 class="white--text">
          {{ $t('finances.account_statement') }}
        </h3>
      </v-card-title>

      <v-data-table
        :headers="statementsTableHeaders"
        :items="statementsTable.items"
        :loading="statementsTable.loading"
        :items-per-page="itemsPerPage"
        :page="currentPage"
        hide-default-footer
        @page-count="getPageCount($event)"
      >
        <template #item.debit="{ item }">
          $ {{ toFixedByDefault(Number(item.debit)) }}
        </template>

        <template #item.credit="{ item }">
          $ {{ toFixedByDefault(Number(item.credit)) }}
        </template>

        <template #footer>
          <data-table-footer
            :items-per-page="itemsPerPage"
            :page-count="pageCount"
            :current-page="currentPage"
            @change-items-per-page="changeItemsPerPage($event)"
            @change-current-page="changeCurrentPage($event)"
          />
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
  import DataTableFooter from '@clickadilla/components/ui/DataTableFooter.vue'
  import { toFixedByDefault } from '@clickadilla/components/utils/to-fixed-by-default.js'
  import DatePickerSelect from '@clickadilla/components/DatePickerSelect/index.vue'
  import { financesStaticticsRepository } from '@/services/repository-factory.js'

  export default {
    name: 'AccountStatementTable',
    components: {
      DataTableFooter,
      DatePickerSelect
    },
    data() {
      return {
        statementsTable: {
          loading: false,
          items: []
        },
        periodInner: {
          start: '',
          end: ''
        },
        itemsPerPage: 15,
        pageCount: 0,
        currentPage: 1
      }
    },
    computed: {
      statementsTableHeaders() {
        return [
          { text: this.$t('finances.date'), value: 'date' },
          { text: this.$t('finances.debit'), value: 'debit' },
          { text: this.$t('finances.credit'), value: 'credit' }
        ]
      },
      period: {
        get() {
          return this.periodInner
        },
        set(value) {
          this.periodInner = value
          this.fetchStatements()
        }
      }
    },
    mounted() {
      this.fetchStatements()
    },
    methods: {
      fetchStatements() {
        this.statementsTable.loading = true
        financesStaticticsRepository
          .list(this.period)
          .then((statements) => {
            this.statementsTable.items = statements
          })
          .finally(() => {
            this.statementsTable.loading = false
          })
      },
      changeItemsPerPage(event) {
        this.itemsPerPage = event
      },
      getPageCount(event) {
        this.pageCount = event
      },
      changeCurrentPage(event) {
        this.currentPage = event
      },
      toFixedByDefault
    }
  }
</script>

<style lang="scss" scoped>
  .finances {
    &__header-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 25px 0 19px 0;

      .datepicker {
        width: 40%;

        ::v-deep .v-text-field {
          padding: 0;
          margin: 0;
        }
      }
    }
  }
</style>
